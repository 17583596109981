import { createContext, ReactNode, useContext, useState } from "react";

type PortfolioContextType = {
  deployedAmount: number;
  setDeployedAmount: (value: any) => any;
  esg: number;
  setEsg: (value: any) => any;
  sectors: any;
  setSectors: (value: any) => any;
  profitLoss: any;
  setProfitLoss: (value:any) => any;
};

const init = {
  deployedAmount: 0.00,
  setDeployedAmount: () => null,
  esg: 0.00,
  setEsg: () => null,
  sectors: [],
  setSectors: () => null,
  profitLoss: [],
  setProfitLoss: () => null,
};

export const PortfolioContext = createContext<PortfolioContextType>(init);

export const PortfolioContextProvider = (props: { children: ReactNode }) => {
  const [deployedAmount, setDeployedAmount] = useState(0.00);
  const [esg, setEsg] = useState(0.00);
  const [sectors, setSectors] = useState<any>([]);
  const [profitLoss, setProfitLoss] = useState<any>([]);

  return (
    <PortfolioContext.Provider value={{ deployedAmount, setDeployedAmount, esg, setEsg, sectors, setSectors, profitLoss, setProfitLoss }}>
      {props.children}
    </PortfolioContext.Provider>
  );
};

export const usePortfolio = () => useContext(PortfolioContext);
