import '../styles/globals.css'
import '../styles/react-toggle.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from "next-auth/react";
import { RecoilRoot } from 'recoil'
import { ModalContextProvider } from '../contexts/ModalContext';
import { VfundsContextProvider } from '../contexts/VfundsContext';
import { PortfolioContextProvider } from '../contexts/PortfolioContext';
import { TickerContextProvider } from '../contexts/TickerContext';
import { LoadingContextProvider } from '../contexts/Loading';
import { DarkThemeContextProvider } from '../contexts/DarkTheme';
import { appWithTranslation } from "next-i18next";
import TopProgressBar from '../components/TopProgressBar'
import "nprogress/nprogress.css";

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {

  return (
    <>
      <SessionProvider session={session}>
        <DarkThemeContextProvider>
          <LoadingContextProvider>
            <TickerContextProvider>
              <VfundsContextProvider>
                <PortfolioContextProvider>
                  <ModalContextProvider>
                    <RecoilRoot>
                      <TopProgressBar />
                      <Component {...pageProps} />
                    </RecoilRoot>
                  </ModalContextProvider>
                </PortfolioContextProvider>
              </VfundsContextProvider>
            </TickerContextProvider>
          </LoadingContextProvider>
        </DarkThemeContextProvider>
      </SessionProvider>
    </>
  )
}

export default appWithTranslation(MyApp)
